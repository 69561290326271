import { MessageResponseModel, EventType, ChatConnectionStatus } from './enums';
//import FingerprintJS from '@fingerprintjs/fingerprintjs';
import SHA256 from 'crypto-js/sha256';

// Simple hash function (not cryptographically secure)
function hashString(str: string): string {
    return SHA256(str).toString();
}

let language = navigator.language;
let userAgent = navigator.userAgent;

function getBrowserFingerprint(): string {
    const userAgent = navigator.userAgent;
    const screenResolution = `${window.screen.width}x${window.screen.height}`;
    const language = navigator.language;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const plugins = Array.from(navigator.plugins).map(p => p.name).join(',');
    const hardwareConcurrency = navigator.hardwareConcurrency || '';
    const deviceMemory = (navigator as any).deviceMemory || ''; // Some browsers don't support this
    const touchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

    // Canvas Fingerprinting
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx!.textBaseline = 'top';
    ctx!.font = '14px Arial';
    ctx!.textBaseline = 'alphabetic';
    ctx!.fillStyle = '#f60';
    ctx!.fillRect(125, 1, 62, 20);
    ctx!.fillStyle = '#069';
    ctx!.fillText('fingerprint', 2, 15);
    ctx!.fillStyle = 'rgba(102, 204, 0, 0.7)';
    ctx!.fillText('fingerprint', 4, 17);
    const canvasHash = canvas.toDataURL();

    // WebGL Fingerprinting
    const gl = canvas.getContext('webgl') as WebGLRenderingContext | null || 
           canvas.getContext('experimental-webgl') as WebGLRenderingContext | null;

    let webglHash: string = '';
    if (gl) {
        const webglVendor = gl.getParameter(gl.VENDOR);
        const webglRenderer = gl.getParameter(gl.RENDERER);
        webglHash = `${webglVendor}~${webglRenderer}`;
    }

    // Combine all properties into a single string
    const fingerprintData = [
        userAgent,
        screenResolution,
        language,
        timezone,
        plugins,
        hardwareConcurrency,
        deviceMemory,
        touchSupport,
        canvasHash,
        webglHash
    ].join('###');

    // Hash the combined string to create a unique fingerprint
    return hashString(fingerprintData);
}

class ChatService {
    private static instance: ChatService;
    url: string | null = null;
    private chat_url_private: string;
    private chat_url_public: string;
    private socket: WebSocket | null = null;
    agentType: string | null = null;
    onMessageReceived: ((message: MessageResponseModel) => void) | null = null;
    onConnectionStatusChanged: ((status: string) => void) | null = null;
    reconnectInterval: number = 500;
    private connected = false;
    private access_token: string | null = null;
    user_name: string | null = null;

    constructor(url_public: string, url_private: string) {
        this.chat_url_public = url_public;
        this.chat_url_private = url_private;

        if (ChatService.instance) {
            return ChatService.instance;
        }
        ChatService.instance = this;
        return this;
    }

    private canConnect(): boolean {
        if (this.socket && (this.socket.readyState === WebSocket.OPEN || this.socket.readyState === WebSocket.CONNECTING)) {
            if (this.socket.readyState === WebSocket.CONNECTING) {
                console.log('WebSocket is already connecting...');
            } else {
                console.log('WebSocket is already connected');
            }
            return false;
        }

        return true;
    }

    connect(): void {
        if (!this.canConnect()) {
            return;
        }

        console.log('Connecting to:', this.url, "...");

        if (this.onConnectionStatusChanged) {
            this.onConnectionStatusChanged(ChatConnectionStatus.Connecting);
        }

        this.connected = false;

        // append agent type to the URL if not null
        const fullUrl = this.url + (this.agentType ? `?agent=${this.agentType}` : '');
        this.socket = new WebSocket(fullUrl);

        this.socket.onopen = () => {
            console.log('WebSocket connected to:', this.url);
            this.reconnectInterval = 500;
            this.initialSync();
        };

        this.socket.onmessage = (event: MessageEvent) => {
            this.handleResponse(event.data);
        };

        this.socket.onclose = (event: CloseEvent) => {
            console.log('WebSocket disconnected:', event.code, event.reason);
            this.connected = false;
            if (this.onConnectionStatusChanged) {
                this.onConnectionStatusChanged(ChatConnectionStatus.Disconnected);
            }
            if (event.code !== 1000) { // 1000 means a normal closure
                console.log('Reconnecting websocket...');
                setTimeout(() => {
                    this.connect(); // Attempt to reconnect
                }, this.reconnectInterval);
                this.reconnectInterval = Math.min(this.reconnectInterval * 2, 5000); // Exponential backoff
            }
        };

        this.socket.onerror = (error: Event) => {
            console.error('WebSocket error:', error);
        };
    }

    private handleResponse(data: string): void {
        try {
            const message: MessageResponseModel = JSON.parse(data);
            switch (message.event) {
                case EventType.Auth:
                    console.log('Auth event received');
                    this.handleAuthStart(message);
                    break;

                case EventType.InitSync:
                    console.log('InitSync event received');
                    break;

                case EventType.SyncCompleted:
                    console.log('SyncCompleted event received');
                    this.handleSyncCompleted(message);
                    break;

                case EventType.ProfileChanged:
                    console.log('ProfileChanged event received');
                    this.handleProfileChanged(message);
                    break;

                case EventType.SetUpPushNotification:
                    console.log('SetUpPushNotification event received');
                    break;

                default:
                    this.didReceiveMessage(message);
                    break;
            }
        } catch (error) {
            console.error('Failed to parse message:', error);
        }
    }

    async getAccessToken() {
        // default to public chat for cases where loggin fails
        this.url = this.chat_url_public;

        try {
            const url = getUserBackendUrl();
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include'  // Include cookies in the request
            });
            if (response.status === 401) {
                // if the user is not logged in, we continue as anonymous
                return;
            }
            
            if (!response.ok) {
                throw new Error('Failed to fetch token');
            }
            
            const data = await response.json();
            if (data.not_logged_in) {
                console.log('User is not logged in');
                return;
            }
            this.access_token = data.access_token;
            this.user_name = data.user_name;
            this.url = this.chat_url_private;
            console.log('Fetched remedee access token');
        } catch (error) {
            console.error('Error:', error);
            // currently we just silently ignore the error to use the chat as anonymous user
            //throw error;  // Rethrow the error to handle it outside
        }
    }

    async init() {
        if (!this.canConnect()) {
            return;
        }

        try {
            // Await login to ensure the token is fetched before connecting
            // TODO: handle error
            await this.getAccessToken();
            this.connect();
            console.log('Connected to chat service');
        } catch (error) {
            console.error('Failed to login and connect:', error);
        }
    }

    send(message: string): boolean {
        if (!this.socket || this.socket!.readyState !== WebSocket.OPEN || !this.connected) {
            console.log('Socket is not ready or not connected.');
            return false;
        }
        this.sendMessage({ msg: message, isComplete: true });
        return true;
    }

    initialSync(): void {
        if (this.access_token != null) {
            console.log(`Initial sync for user ${this.user_name}`);
        } else {
            console.log('Initial sync for anonymous user');
        }

        //const authToken = this.loadAuthToken();
        const initialData: Record<string, any> = {
            deviceId: this.getDeviceId(),
            accessToken: this.access_token,
            event: EventType.InitSync,
            data: {
                lastSyncTimestamp: this.getLastSyncTimestamp(),
                languages: navigator.languages,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        };

        // if (authToken) {
        //     initialData.authToken = authToken;
        // }

        language = "xz";
        this.sendMessage(initialData);
    }

    private sendMessage(initialData: Record<string, any>): void {
        this.socket?.send(JSON.stringify(initialData));
    }

    private handleAuthStart(data: Record<string, any>): void {
        if (data.data && data.data.authToken) {
            this.saveAuthToken(data.data.authToken);
        }
        if (this.onConnectionStatusChanged) {
            this.onConnectionStatusChanged(ChatConnectionStatus.Auth);
        }
        
        // anonymous login
        const fingerprint = getBrowserFingerprint();
        const authMessage = { msg: "anonymous:" + fingerprint, isComplete: true };
        this.sendMessage(authMessage);
    }

    private handleSyncCompleted(data: Record<string, any>): void {
        this.saveProfile(data.data);
        this.connected = true;
        this.send(`\\connect:${language}:${userAgent}`);
        if (this.onConnectionStatusChanged) {
            this.onConnectionStatusChanged(ChatConnectionStatus.Connected);
        }
    }

    private handleProfileChanged(data: Record<string, any>): void {
        this.saveProfile(data.data);
    }

    private saveAuthToken(token: string): void {
        localStorage.setItem('authToken', token);
    }

    private loadAuthToken(): string | null {
        return localStorage.getItem('authToken');
    }

    private saveProfile(profileData: Record<string, any>): void {
        localStorage.setItem('userName', profileData.userName);
        localStorage.setItem('userMail', profileData.userMail);
        localStorage.setItem('userId', profileData.userId);
    }

    private getDeviceId(): string {
        return getBrowserFingerprint()
        /*
        let deviceId = localStorage.getItem('deviceId');
        if (!deviceId) {
            deviceId = crypto.randomUUID();
            localStorage.setItem('deviceId', deviceId);
        }
        return deviceId;*/
    }

    private getLastSyncTimestamp(): string {
        return localStorage.getItem('lastSyncTimestamp') || '';
    }

    close(): void {
        if (this.socket) {
            console.log('closing connection...');
            this.socket.close();
        }
    }

    private didReceiveMessage(message: MessageResponseModel): void {
        // Implement the logic to handle received messages
        if (message.msg && this.onMessageReceived) {
            this.onMessageReceived(message);
        } else {
            console.log('Received message:', message);
        }
    }
}

function getChatUrl(instance: string): string {
    // if this is running on localhost, then we must use the local server as Safari in combintaion with the Fritz Box DNS Rebind
    // won't work with the public server when it is forwarding to a local server.

    if (localNetwork) {
        if (window.location.href.startsWith('http://')) {
            return 'ws://192.168.178.45:19008/';
        } else {
            return `wss://${window.location.hostname}:17777/chat/`;
        }
        //return 'wss://ap2.remedee.ai/chat/';
    } else {
        return `wss://ap2.remedee.ai/chat/${instance}/`;
    }
}

function getUserBackendUrl(): string {
    // if this is running on localhost, then we must use the local server as Safari in combintaion with the Fritz Box DNS Rebind
    // won't work with the public server when it is forwarding to a local server.

    if (localNetwork) {
        return '/users/access_token';
    } else {
        return 'https://users.remedee.ai/users/access_token';
    }
}

// override with localhost for debugging
export const localNetwork =
    window.location.hostname === "localhost"
    || window.location.hostname === "127.0.0.1"
    || window.location.hostname.startsWith("192.168.")
    || window.location.hostname.startsWith("dev-macbook.")

// TODO: use "public" here once migrated
const sharedChatService = new ChatService(getChatUrl("public"), getChatUrl("private"));
export default sharedChatService;
