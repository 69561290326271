// enums.ts
export enum ChatConnectionStatus {
    Connecting = 'connecting',
    Disconnected = 'disconnected',
    Auth = 'auth',
    Connected = 'connected',
  }
  
  export enum EventType {
    Auth = 'auth',
    InitSync = 'initial_sync',
    SyncCompleted = 'init_sync_complete',
    ProfileChanged = 'profile_changed',
    SetUpPushNotification = 'setup_push_notifications',
  }
  
  export interface MessageResponseModel {
    timestamp: string;
    from?: number;
    msg?: string;
    isComplete?: boolean;
    event?: EventType;
    data?: {
      authToken?: string;
      userName?: string;
      userMail?: string;
      userId?: number;
      contacts?: number[];
      deviceToken?: string;
    };
  }
  
  export interface ChatMessage {
    content?: string;
    userId?: number;
    isComplete: boolean;
    timeStamp: string;
  }
  