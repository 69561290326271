// ChatStyles.js
import styled, { keyframes } from 'styled-components';

export const RegisterButton = styled.a`
  position: fixed;
  top: 15px;
  right: 15px;
  width: 80px;
  text-align: center;
  padding: 5px 0;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 0.5px solid #99d;
  color: #800;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    background-color: #f8f8f8;
  }
`;

export const LoginButton = styled.a`
  position: fixed;
  top: 15px;
  right: 102px;
  width: 80px;
  text-align: center;
  padding: 5px 0;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 0.5px solid #99d;
  color: #800;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    background-color: #f8f8f8;
  }
`;

export const ProfileButton = styled.a`
  position: fixed;
  top: 15px;
  right: 15px;
  padding: 10px 20px;
  background-color: #dfd;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 0.5px solid #99d;
  color: #800;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    background-color: #f8f8f8;
  }
`;

const blinkCursor = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const BlinkingCursor = styled.span`
  display: inline-block;
  width: 10px; /* Adjust based on your image size */
  height: 15px; /* Adjust based on your image size */
  margin-left: 5px; /* Add spacing between text and image */
  position: relative; /* Use relative positioning */
  top: 3px; /* Move it down by 5px */
  background: url('/block_dark.png') no-repeat;
  background-size: cover; /* Adjust to fit the image */
  animation: ${blinkCursor} 1s step-start infinite; /* Blinking effect */
`;

export const TypingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TypingText = styled.span`
  font-size: 14px;
  color: #61dafb;
  margin-right: 10px; /* Add spacing between text and image */
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh; //calc(var(--vh, 1vh) * 100);
  background-color: #fff;
  color: #ffffff;
  font-family: Arial, sans-serif;
  overflow: hidden; /* Prevents overflow from causing scroll on the whole page */
`;

export const Header = styled.h1`
  position: fixed;
  top: 20px;  /* Adjust the distance from the top */
  left: 20px;  /* Adjust the distance from the left */
  margin: 0;   /* Remove default margin */
  color: #555;
  z-index: 1000;  /* Ensure it stays above other content */
  font-size: 1em;
`;

export const IntroText = styled.p`
  font-size: 1.2em;
  color: #dcdcdc;
  text-align: center;
  max-width: 800px;
  margin: 0px;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1000px;
  background-color: #fff;
  scrollbar-color: #f8f8f8 #f8f8f8; /* Scrollbar color */
  //margin: 10px;
  margin-top: 30px;
  border-radius: 8px;
  padding: 20px;
  //box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  flex: 1;
  overflow: hidden;
  font-family: 'Fira Code', monospace;
  
  // height: 100%; /* Ensure the chat container takes full height */

  @media (max-width: 1280px) {
    margin: 0px; /* Adjust margin for screens smaller than 600px */
    margin-top: 60px;
    height: 60%;
    //height: calc(10vh - 60px); /* Adjust for mobile view */
  }
  @media (max-width: 600px) {
    margin: 0px; /* Adjust margin for screens smaller than 600px */
    margin-top: 60px;
    height: 60%;
    //height: calc(10vh - 60px); /* Adjust for mobile view */
  }
`;

// export const MessagesContainer = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   // justify-content: flex-end; /* This aligns messages to the bottom */
//   height: 800px;
//   overflow-y: auto;
//   padding-right: 10px;
//   border-bottom: 1px solid #01354b;
//   max-height: 100%;
// `;

export const MessagesContainer = styled.div`
  flex: 1; /* This makes the messages container grow to fill available space */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-right: 10px;
  //border-bottom: 1px solid #01354b;

  // min-height: 90%; /* Ensure container grows to the full height */
`;

interface MessageProps {
  $isUser: boolean;
}

export const Message = styled.div<MessageProps & { isTyping?: boolean }>`
  background-color: ${(props) => (props.$isUser ? '#eee' : '#fff')};
  color: ${(props) => (props.isTyping ? '#000' : '#000')};
  padding: 0px 4px;
  border-radius: 12px;
  margin-bottom: 10px;
  max-width: ${(props) => (props.$isUser ? '70%' : '100%')};
  width: fit-content;
  align-self: ${(props) => (props.$isUser ? 'flex-end' : 'flex-start')}; /* Keeps the message bubble aligned */
  text-align: left; /* Ensure the text is left-aligned inside the bubble */
  margin-left: ${(props) => (props.$isUser ? 'auto' : '0')};
  word-break: break-word;
`;

export const InputContainer = styled.div`
  display: flex;
  padding:  3px 6px;
  align-items: center;
  border-radius: 22px;  
  background-color: #eee; /* Style the input container */
`;

export const Input = styled.textarea`
  flex: 1;  
  height: auto;  
  min-height: 18px;  
  font-size: 16px;  
  padding: 10px;
  border: none;  
  border-radius: 20px;  
  margin-right: 10px;  
  background-color: #eee;  
  color: #000;  
  resize: none;  
  line-height: 1.5;  
  overflow: hidden;
  outline: none;  /* Remove the default focus outline (blue corner) */
  &:focus {
    outline: none;  /* Ensure no outline appears on focus */
  }
`;

export const SendButton = styled.button<{ disabled?: boolean }>`
  padding: 0px 0px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  background-color: #222;
  color: #aae;
  font-weight: bold;
  cursor: pointer;
  font-size: 1.3rem;
  
  &:hover {
    background-color: #222;
    color: #ccf;
  }

  &:disabled {
    background-color: #ccc; /* Dark gray when disabled */
    cursor: not-allowed; /* Show "not allowed" cursor when button is disabled */
    color: #ddd;
  }
`;
